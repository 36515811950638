<template>
  <div class="phase3">
    <vue-gallery
      id="gallery-update-phase4"
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery>
    <div>
      <section>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph") }}</div>
      </section>

      <!-- ************* WHO TO INVOLVE ************* -->
      <section>
        <div class="bsat__subtitle mb-4">{{ $t("whoToInvolve.title") }}</div>

        <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph") }}</div>
        <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph2") }}</div>
      </section>

      <!-- ***************** STEPS ****************** -->
      <div class="steps">
        <div class="bsat__subtitle">{{ $t("stepsTitle") }}</div>
        <v-stepper class="elevation-0 bg-off_white" v-model="steps">
          <!-- ************* STEPPER HEADER **************** -->
          <v-stepper-header
            :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '50%' : '' }"
          >
            <v-stepper-step @click="steps = 1" step="1"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="steps = 2" step="2"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="steps = 3" step="3"> </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step @click="steps = 4" step="4"> </v-stepper-step>
          </v-stepper-header>

          <!-- ***************** STEPPER CONTENT ***********************-->
          <v-stepper-content
            v-for="(step, i) in phase3"
            :key="i"
            :step="i + 1"
            class="steps__content px-0"
          >
            <keep-alive>
              <component :is="step.component"></component>
            </keep-alive>
          </v-stepper-content>
        </v-stepper>
      </div>

      <Divider></Divider>
      <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>

      <!--************************* TABS **************************** -->
      <div class="phase3__tabs">
        <v-tabs
          v-model="tab"
          class="bsat__tabs"
          background-color="transparent"
          slider-color="dark"
          color="dark"
          show-arrows
          center-active
        >
          <v-tab
            v-for="(item, i) in footerTabs"
            :key="i"
            class="mr-4 mr-sm-8"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'min-width: 90px'
                : 'min-width: 160px'
            "
          >
            {{ $t(item.tab) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="py-4">
          <!-- ********** REAL EXAMPLES TAB ******* -->
          <v-tab-item>
            <div class="bg-off_white">
              <div class="bsat__paragraph">
                <!-- {{ $t("realExamples.paragraph") }} -->
                To be added by the end of March 2021
              </div>
              <!-- <ul>
                <li v-for="(item, i) in examples" :key="i">
                  <div class="bsat__paragraph">
                    <strong>{{ item.title }}</strong>
                  </div>
                  <a :href="realExamplesFiles[i]" target="_blank" download>
                    <v-btn
                      class="bsat__btn"
                      color="dark"
                      dark
                      depressed
                      width="150"
                      height="26"
                    >
                      {{ $t("download") }}
                    </v-btn>
                  </a>
                </li>
              </ul> -->

              <v-row v-for="(item, i) in examples" :key="i" align="center">
                <v-col cols="12" md="3">
                  <v-img
                    class="elevation-2 rounded"
                    style="height: 200px !important"
                    :src="require(`@/assets/icons/regions/${item.icon}`)"
                    @click="index = i"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="9">
                  <div class="bsat__paragraph">
                    <strong>{{ item.title }}</strong>
                  </div>
                  <a
                    v-if="realExamplesFiles[i]"
                    :href="realExamplesFiles[i]"
                    target="_blank"
                    download
                    class="mr-4"
                  >
                    <v-btn
                      class="bsat__btn"
                      color="dark"
                      dark
                      depressed
                      width="150"
                      height="26"
                    >
                      {{ $t("download") }}
                    </v-btn>
                  </a>
                  <a
                    v-if="furtherInfoLinks[i]"
                    :href="furtherInfoLinks[i]"
                    target="_blank"
                    download
                  >
                    <v-btn
                      class="bsat__btn"
                      color="dark"
                      dark
                      depressed
                      width="150"
                      height="26"
                    >
                      {{ $t("Further Information") }}
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <!-- ********** NOTES TAB ******* -->
          <v-tab-item>
            <div class="bg-off_white">
              <div class="bsat__subtitle">{{ $t("notes.title") }}</div>

              <div class="bsat__subtitle">{{ $t("notes.note1.title") }}</div>
              <div class="bsat__image">
                <img src="@/assets/avatars/11.jpg" />
              </div>
              <div class="bsat__paragraph">
                {{ $t("notes.note1.paragraph") }}
              </div>
              <div class="bsat__paragraph">
                {{ $t("notes.note1.paragraph2") }}
              </div>

              <div class="bsat__subtitle">{{ $t("notes.note2.title") }}</div>
              <div class="bsat__image">
                <img src="@/assets/avatars/12.png" />
              </div>
              <!-- <div class="bsat__paragraph">
                {{ $t("notes.note1.paragraph") }}
              </div> -->
              <div class="bsat__paragraph">
                {{ $t("notes.note1.paragraph3") }}
              </div>
            </div>
          </v-tab-item>

          <!-- ********** OPINIONS TAB ******* -->
          <!-- <v-tab-item>
            <v-card flat color="off_white">
              <v-card-text class="px-0">
                <v-list dense class="bg-off_white">
                  <v-list-item
                    dense
                    class="text-default"
                    v-for="(item, i) in currentOpinions"
                    :key="i"
                  >
                    <v-list-item-avatar size="40">
                      <v-img src="@/assets/icons/profile.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-pagination
                    class="pagination"
                    color="dark"
                    v-model="page"
                    :length="2"
                  ></v-pagination>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "whoToInvolve": {
      "title": "Who to involve in this phase?",
      "paragraph": "It is recommended to involve the same actors that during Phase 3. The specific moment to engage them for the roadmap definition and its regularity should be defined in the planning of the participatory process (step 1 in Phase 3). Also, if necessary, it needs to be updated after the lessons learnt in Phase 3.",
      "paragraph2": "Step 1/Recommended resources: After going to the trainings and really clicking on one of the links to Biochemicals, etc. training, the 'back' button does not work."
    },
    "notes": {
        "note1": {
            "title": "SPRING regions (13 regions now), Sara Cantone",
            "paragraph": "Position/role in the development of the regional bioeconomy strategy: Coordinator of the Table of the SPRING Regions (supporting/advisory role).",
            "paragraph2": "As SPRING, not only we worked with Piemonte Region as case study to implement the bioeconomy strategy, but also with other Italian regions (among them, Friuli Venezia Giulia, who developed the Regional Position Paper on bioeconomy). The main general output, beside the specific results on specific regions, has been to have more instruments to support the dialogue with and among regions, not only with the more advanced ones with already bioeconomy-related strategies and activities in place. This resulted in concrete dialogue and cooperation – also through the Table of SPRING Regions- on different aspects related to the deployment of regionals bioeconomy and on the possibility to jointed actions within the policy instruments at regional and national level.",
            "paragraph3": "Clusters are part of a support group which was consulted during the Bavarian strategy process. Together with associations and industry representatives we contributed expertise, industry insights and supported the conduction of workshops and expert interviews. Since the start of the project, the bioeconomy in Bavaria became remarkably stronger. POWER4BIO supported us like a coach with a versatile toolbox. It provided a managed and structured assessment procedure, a stable platform for cooperation and an open space for exchange and mutual learning. This facilitated the understanding between regions and the use of a common language, to pursue common identified goals. It enabled us to measurably compare ourselves with peer regions, identify SWOTs and stimulate a dialogue in the region."
        },
        "note2": {
            "title": "Bavaria, Laura Kühn",
            "paragraph": "Position/role in the development of the regional bioeconomy strategy:Clusters are part of a support group which was consulted during the Bavarian strategy process. Together with associations and industry representatives we contributed expertise, industry insights and supported the conduction of workshops and expert interviews.",
            "paragraph2": "Since the start of the project, the bioeconomy in Bavaria became remarkably stronger. POWER4BIO supported us like a coach with a versatile tool box. It provided a managed and structured assessment procedure, a stable platform for cooperation and an open space for exchange and mutual learning. This facilitated the understanding between regions and the use of a common language, to pursue common identified goals. It enabled us to measurably compare ourselves with peer regions, identify SWOTs and stimulate a dialogue in the region."
        },
      "tabTitle": "Notes",
      "title": " Other achievements of the Western and Southern Regions of POWER4BIO:"
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "paragraph": "At the very last phase, the region is ready to finish the update of the implementation plan of the bioeconomy strategy. This implies the identification of reviewed priority areas as key drivers of its updated bioeconomy strategy. As a complementary exercise, an analysis of the financial and regulatory environments is encouraged to sustain those priorities. Lastly, a robust and structured engagement with key stakeholders is suggested. The open dialogue with a large number of experts coming from existing and new value chains to promote will feed into the updated bioeconomy ecosystem in your region. This large involvement of key stakeholders will allow to deploy the solutions and actions highlighted in the updated plan.",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "examples": [
        {
          "icon": "BAVARIA.jpg",
          "title": "Actions to regional bioeconomy challenges in Bavaria (Germany)"
        },
        {
          "icon": "CENTRAL_GERMANY.jpg",
          "title": "Actions to regional bioeconomy challenges in Central Germany (Germany)"
        },
        {
          "icon": "FLANDERS.jpg",
          "title": "Actions to regional bioeconomy challenges in Flanders (Belgium)"
        },
        {
          "icon": "ITALY.jpg",
          "title": "Actions to regional bioeconomy challenges in Italian regions (Italy)"
        }
      ],
      "paragraph": "Check the following planned or implemented actions to regional bioeconomy challenges in the Western Europe regions of POWER4BIO",
      "tabTitle": "Real Examples"
    },
    "stepsTitle": "Overview of the steps",
    "subtitle": "Who to involve in this phase?",
    "title": "Phase 4: Update of implementation plan"
  }
}
</i18n>

<script>
import VueGallery from "vue-gallery";
import { EventBus } from "@/utils/EventBus";
import { getArrayPiece } from "@/utils/helpers";
import Divider from "@/components/Divider";

export default {
  name: "UpdatePhase4",
  components: {
    Step1: () => import("./Step1"),
    Step2: () => import("./Step2"),
    Step3: () => import("./Step3"),
    Step4: () => import("./Step4"),
    Divider,
    VueGallery
  },
  data() {
    return {
      page: 1,
      steps: 1,
      tab: 0,
      step2Tab: 0,
      faoLink:
        "http://www.fao.org/climate-change/our-work/areas-of-work/bioeconomy/en/",
      scarLink: "https://www.scar-swg-sbgb.eu/the-bioeconomy",
      euCommisionLink: "https://ec.europa.eu/research/bioeconomy/index.cfm",
      footerTabs: [
        { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase3: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" },
        { component: "Step4" }
      ],
      furtherInfoLinks: [
        // "",
        "https://www.stmwi.bayern.de/fileadmin/user_upload/stmwi/Publikationen/2021/2021-02-02_FutureBioeconomyBavaria_BF.pdf",
        "https://www.mitteldeutschland.com/de/mitteldeutschland-als-modellregion-der-biooekonomie/",
        "https://www.ewi-vlaanderen.be/nieuws/beleidsplan-moet-bio-economie-verankeren-vlaanderen#:~:text=Vlaams%20minister%20van%20Economie%20%26%20Innovatie,het%20beleidsplan%20bio%2Deconomie%20voor.&text=Het%20plan%20legt%20de%20nadruk,in%2010%20miljoen%20euro%20voorzien",
        "".default
      ],
      realExamplesFiles: [
        // "",
        "",
        "",
        require("@/assets/files/Flandes_PositionPaperBioeconomy.docx").default,
        require("@/assets/files/FVG_PositionPaperBioeconomy.pdf").default
      ],
      index: 0,
      images: [
        // require("@/assets/icons/regions/ANDALUSIA.jpg"),
        require("@/assets/icons/regions/BAVARIA.jpg"),
        require("@/assets/icons/regions/CENTRAL_GERMANY.jpg"),
        require("@/assets/icons/regions/FLANDERS.jpg"),
        require("@/assets/icons/regions/ITALY.jpg")
      ]
    };
  },
  computed: {
    orderedList() {
      return Object.values(this.$t("orderedList"));
    },
    examples() {
      return Object.values(this.$t("realExamples.examples"));
    },
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.page, 3);
    }
    // step2PolicyAnalysisMaterial() {
    //   return this.$t("step1.policyAnalysis.Materialparagraph").split(":");
    // },
  },
  watch: {
    steps(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.step) {
      this.steps = Number(query.step);
    }

    EventBus.$on("goToStep", stepNumber => {
      this.steps = stepNumber;
    });
  },
  methods: {
    getArrayFromObject(arr) {
      return Object.values(arr);
    },
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase3 {
  margin-top: 50px;

  .example {
    font-family: $font-family !important;

    .v-list-item__title,
    .v-list-item__subtitle {
      text-overflow: clip !important;
      white-space: normal !important;
      letter-spacing: normal;
      line-height: normal;
    }

    &__title {
      font-size: 16px;
    }
  }

  .v-window {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }

    .bsat__image img {
      object-fit: cover;
      width: 120px;
      height: 120px;
      margin: auto 2px;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      width: 36px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }
}
</style>
